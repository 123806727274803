<template>
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">
<header class="main-header"> 
  <!-- Logo -->
  <a href="/pages/dashboard" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">
      <svg>
        <use xlink:href="/img/logo.svg#iservice-logo" />
      </svg>
    </span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg">
      <svg>
        <use xlink:href="/img/logo.svg#iservice-logo" />
      </svg>
      <b>Service <span class="register-trade-mark">&reg;</span></b>
    </span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button" @click="addBodyClass">
      <span class="sr-only">Toggle navigation</span>
    </a>


    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <!-- Chat: style can be found in dropdown.less-->
        <li class="dropdown messages-menu" data-toggle="tooltip" data-placement="bottom" data-original-title="The number of waiting chats available for you. Click to get a chat.">
          <a href="#" class="dropdown-toggle">
            <i class="fa fa-comment-o"></i>
            <span class="label label-danger blink">3</span>
          </a>
        </li>
        <li class="dropdown messages-menu" data-toggle="tooltip" data-placement="bottom" data-original-title="The number of proposed answers waiting for approval. Click to get the next QA answer.">
          <a href="/pages/my-messages-to-approve.html" class="dropdown-toggle" ng-click="loader = true;">
            <i class="fa icon-c icon-msg-pending"  ng-hide="loader"></i>
            <i class="fa fa-spinner fa-spin fa-fw ng-hide" ng-cloak ng-show="loader"></i>
            <span class="label label-danger">2</span>
          </a>
        </li>
        <li class="dropdown messages-menu" data-toggle="tooltip" data-placement="bottom" data-original-title="System Alerts">
          <a href="/pages/alert-messages.html" class="dropdown-toggle" ng-click="loader = true;">
            <i class="fa fa-bell-o"  ng-hide="loader"></i>
            <i class="fa fa-spinner fa-spin fa-fw ng-hide" ng-cloak ng-show="loader"></i>
            <span class="label label-warning">10</span>
          </a>
        </li>
        <!-- Messages: style can be found in dropdown.less-->
        <li class="dropdown messages-menu" data-toggle="tooltip" data-placement="bottom" data-original-title="The number of available messages. Click to get the next message.">
          <a href="#" class="dropdown-toggle">
            <i class="fa fa-envelope-o"></i>
            <span class="label label-danger">4</span>
          </a>
        </li>
        <!-- Tasks: style can be found in dropdown.less -->
        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
           <img width="25" class="round" height="25" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAATdJREFUSEtjlNoz5z8DjQHjqCWkhPBocJESWgx4g2u/RTCDFAc3Q/WNYwxrXtyBG7zS0JPBRkgKw6Llz24xlFw/jCGO05IQCRWGShVTsIb9b5+gaAZZosItwJB39SDD0ffPwGp6NG0ZQHqmPrzI0H3vHIpFOC0BGSTGzsXw6uc3DAOxWWItKMUwSdue4c7XDwzh57cTtgSmAeSDlz+/MqTI6TDMeXQF7kKqWFKqZMQQJ6PJ0HjrJDguQHED8hHMhVQJLpAhIAAzFBTejsIy8DigOOJBkdeqYcXAw8yKM/Vg8wm+NI0R8eiuhmkGBRkIOJ5Yy0CxJejhD7MEZLmvuCI4z4RKqmKkOKJ9Aopw9JQE0wwLxgsfX4OF0PMJ0ZaQVFaQoHi0FCYhsBjwl8IkmYRH8WickBSSAKMH6CPBHRV4AAAAAElFTkSuQmCC" alt="Alek Packard">
           <span class="hidden-xs">Alek Packard</span>
          </a>
          <ProfileDropdown/>
        </li>
        <!-- Control Sidebar Toggle Button -->
        <li>
          <a href="#" @click="sideBarClass"><i class="fa fa-gears"></i></a>
        </li>
      </ul>
    </div>
  </nav>
</header>
</template>


<script>

import ProfileDropdown from './ProfileDropdown.vue';

let myBody = null;
let sideBar = null;
export default {
  name: 'HeaderComponents',
  data () {
        return {
            isActive: false // This is used to toggle the class on your hamburger button
        }
    },
    methods: {
        addBodyClass: function(){
            this.isActive = true;
            // You must manually add & remove classes to the body element
            if(window.innerWidth >= 767)
              myBody.classList.toggle('sidebar-collapse');

            myBody.classList.toggle('sidebar-open');
           
        },

        sideBarClass: function(){
          this.isActive = true;
          sideBar.classList.toggle('control-sidebar-open');
        }
    },
    mounted () {
      myBody = document.getElementsByTagName('body')[0];
      sideBar = document.getElementById('main-sidebar');
    },
    components: {
      ProfileDropdown
    }
}

</script>
