<template>
  <ul class="dropdown-menu" id="collapseExample">
    <!-- User image -->
    <li class="user-header">
      <img width="60" class="round" height="60" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA+5JREFUaEPtmF1Ik1EYx/+bm7b8DLWJNdO0tLIszD4Iog/oIisJiS6iKLoQiYLqoiiIboogqou+IYiKLrooCPogLT8qS6yU5WdimaZLy9SlW+ncFu+hjdO2tnOmyd513qsxzjnP+T3/5/yf876KxMdX7PiPHoUADnK1hcJBLjCEwkLhIMuAKOkgE9QNRygsFA6yDIiSDjJBhWmJkhYlzZCBjQmpOJa+FNGqMDL6Sc8nbNMXMcwESpfkY2Z4jM+x0mcZk9UCw08THn1tw7mPegxaLT7nuQ4Yk5I+n7kCedpUKH6v/nX4B/Y1PEXJtw6fG2IFpheS4D+YjTjaXMkUg547auDpE6NxY/4aJGuiiAIapYqsf9PwDgebKpiBR+w2VPZ1oWvY7HFOeIgasyMmIUkT5Uxsw2AvCmpLCDzrM2rg3clZ2JuyAGHKEJT3diIrMg4x6jDUDXxD/pv7PsvOobDFZsP5Nj1Ofqj2uvfDaTnYqZtD4klJutxeh+Mtr1h5R/8B4HrWGqyO00Ha8IW2t+R3ZmQsUftYyytc62j0uhleYGmxO9m5WByTQNZ90fcZm6ofjA/wqtipOD17OeJDNegaMmNPfTnWa1OwJTEdSoWCybz8AZYMctuUWSRGs6kfKytvjw/wkRmLSHmpFEo87zVgc81D0I7NYl7+AB9IzUZh0jyolcrxA44IUeN2di4p3yGbFWdaa3D2o55k+u7CdVgYrYXNbvdpXv4AX8xciQ3a6STWa2M38l7f+/cK00p2/BxEYV0pqo1fSOBDaTkoSMokyvsyL17grVMyyPpRqlCS0OudjTj87uW/B6Z7b1FPO3boi51B6bPty7xYgXMnJ2Pt5GSsjtUhUhVKYrX/GMCu+jJnolmo/WpLdO81W0dIW7ja0fBHPId7S396u3n5c/GQ1vw+Mkzi3uhsYuF0jvELmO69781GbNcXuzV/eow38+IFltpf7UAPTrXWoIzhJueaDb+AHepJV7xbhmbsb3zmlmW6CrydNQewr5tWv2WIlG5Vfzc+D5m4VKUHcwPT55Mn6t/Mi/UM88TyNpYbmO69PJv4m3kFNDDde6UyrTJ2Q2pJ3p6caC2maSLJEE/mFdDAdO+VztSBpgrc+9LqFfhExjLnVdOTeQU0MN17WW849Jn3ZF4BC0y7Lu9rGf1242peAQvM2lc91Tc919W8AhaYvjk53oxYXXp+VDwuzV0F3YQIN/MKSGD6HLq+GbFC0+efNq+ABGaFksM47ouHHKDG9KYlgGWWAVHSMhOMe7tCYe6UyWyCUFhmgnFvVyjMnTKZTRAKy0ww7u0KhblTJrMJQmGZCca9XaEwd8pkNuEXWuHh17zxaNMAAAAASUVORK5CYII=" alt="Alek Packard">
      <!-- <img src="/img/user2-160x160.jpg" class="img-circle" alt="User Image">-->
      <p>
        Alek Packard
        <small>alek.packard@1to1service.com</small>
        <small>Auto sign out in 12:00 minutes</small>
      </p>
    </li>
    <!-- Menu Body -->
    <li class="user-body">
      <div class="row">
        <div class="col-4 text-center">
          <a href="#">Profile</a>
        </div>
        <div class="col-4 text-center">
          <a href="#">Subscriptions</a>
        </div>
        <div class="col-4 text-center">
          <a href="/pages/my-stock-responses.html">Stock Responses</a>
        </div>
      </div>
      <!-- /.row -->
    </li>
    <!-- Menu Footer-->
    <li class="user-footer">
      <div class="pull-left dropdown">
        <button class="btn btn-default btn-flat" data-bs-toggle="collapse" href="#changePassword" role="button" aria-expanded="false" aria-controls="changePassword">Change Password</button>
      </div>
      <div class="pull-right">
        <button class="btn btn-default btn-flat">Sign out</button>
      </div>
      <div class="clearfix"></div>
      <div class="change-password collapse" id="changePassword">
        <br>
        <div class="form-group has-feedback">
          <input type="password" class="form-control" placeholder="New Password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <div class="form-group has-feedback">
          <input type="password" class="form-control" placeholder="Confirm Password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <button type="submit" class="btn btn-primary btn-block btn-flat">Submit</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>


<script>
let myBody = null;
export default {
  name: 'ProfileDropdownComponents',
}
</script>
