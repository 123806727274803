<template>  
 <div v-if="warningMessage && !isLogin" class="alert alert-warning alert-dismissible" style="z-index: 1200;">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="warningMessage = false">×</button>
        <h4><i class="icon fa fa-warning"></i> Connection  Lost!</h4>
         Your browser lost its real-time connection to iService. <a href=""><i class="fa fa-refresh"></i></a><span class="more"  v-on:click="showmore = !showmore" v-show="!showmore" >...more</span>
         <div class="" v-show =" showmore">
              iService uses a real-time connection from your browser to the server to provide various updates without the need to reload the page. We've detected that you've lost your connection and aren't getting updates. Reload this page to restablish your real-time connection. If the problem persists, check your internet connection or contact your iService administrator. <span class="more"  v-on:click="showmore = false"> ...less </span>
              <br>Closed: unspecified reason.
          </div>	
      </div>
  <div v-if="brodcastMessage && !isLogin" class="alert alert-info alert-dismissible" style="z-index: 1200;">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="brodcastMessage = false">×</button>
      <h4><i class="icon fa fa-info"></i> Broadcast Message </h4><div id="Broadcast">Testing broadcast</div>
  </div>
<div class="skin-blue sidebar-mini" v-if="!isLogin">
   <div class="wrapper">
      
    
      <Header  v-if="!isLogin" />
      <Sidebar  v-if="!isLogin" />
      <!--<div class="content-wrapper"  :style="{ 'height': `${wrapperHeight}px`}">-->
        <div class="content-wrapper"  style="height:auto; min-height: 2300px;">
        <router-view/>
      </div>
      <Footer v-if="!isLogin" />
      <RightPanelMenu   />
    </div>
  </div>
  <div v-else> 
       <router-view/>
  </div>
</template>

<script>
let wrapperHeight = null;

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Sidebar from './components/Sidebar.vue';
import RightPanelMenu from './components/RightPanelMenu.vue';
import Popper from "vue3-popper";

export default {
  components: {
    Header,
  	Sidebar,
    Footer,
    RightPanelMenu,
    Popper,
    
  },
  
  computed: {
      isLogin() {
        var str = this.$route.name;
        if(typeof(str) != 'undefined'){
          if (str.indexOf("Individual") >= 0)
              return true;
        }
      }
  },
  data() {
        return {
            wrapperHeight: 0,
            showInside: false,
            showSnakeBarMessage : false,
            showmore : false,
            brodcastMessage : true,
            warningMessage : true,
        }
    },
  mounted() {  
    this.wrapperHeight = document.getElementById('sideBox').clientHeight + 200 -120; 
  }, 
  events: {
    closeEvent: function () {
       alert("close event");
      this.hide()
    }
  },
  methods:{
    show: function () {
    	this.showInside = true 
    },
    hide: function () { 
    	console.log('hide')
    	this.showInside = false
    },
    closeToastMessage: function(){
       this.showSnakeBarMessage  = false;
       alert(this.showSnakeBarMessage);
    },
    myFunction: function(){
       this.showSnakeBarMessage =  true;
      // setTimeout(function(){ this.closeToastMessage();}.bind(this), 3000);  
    },
    open() {
      this.$toast.success("Profile saved.", {
        // optional options Object
      });
    },
    hidewarningalert(){
       brodcastMessage = false;
    },

    hidebrodcastalert(){
       warningMessage =  false;
    },


  },

 
}
</script>
<style scoped>
  @import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
</style>

<style scoped>
  @import './assets/css/style.css'
</style>
<style scoped>
  @import './assets/icons/style.css'
</style>
<style src="@vueform/multiselect/themes/default.css"></style>



<style scoped>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css'
</style>
<style scoped>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css'
</style>



<style lang="less">
body{font-size:14px ;}    

</style>

