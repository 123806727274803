import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
import jQuery from 'jquery'
global.$ = jQuery

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap"
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';


import mitt from 'mitt';
const emitter = mitt();
app.config.globalProperties.emitter = emitter;


import tooltip from "./directives/tooltip.js";
import "@/assets/tooltip.css";
app.directive("tooltip", tooltip);


import Toggle from '@vueform/toggle'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';


import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import { ColorPicker } from "vue-accessible-color-picker";

import PrimeVue from "primevue/config";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DataView from 'primevue/dataview';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';



  app.directive('click-outside', {
      
    beforeMount: function (el, binding, vnode) {
        binding.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
          }
        }
        document.body.addEventListener('click', binding.event)
      },
      unmounted: function (el, binding, vnode) {
        document.body.removeEventListener('click', binding.event)
      }
  });
app.use(PrimeVue, { ripple: true  });
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.use(router,SetupCalendar,VueToast).component('Calendar', Calendar).component('Column', Column).component('DataView', DataView).component('DatePicker', DatePicker).component('ColorPicker', ColorPicker).component('DataTable', DataTable).mount('#app')

