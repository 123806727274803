<template>
<div class="clearfix"></div>
<footer class="main-footer copyrightbottom">
  <div class="pull-left">
  &copy; 2008- site.time | date: '%Y' One-to-One Service.com. All rights reserved.
  <span>Powered by iService Version: 8.0 -  site.time | date: '%Y.%m.%d - %H:%M:%S'</span>
  </div>
	<div class="pull-xm-left pull-right">
    <a href="">Anti-Spam Policy</a>
  </div>
	<div class="clearfix"></div>
</footer>


</template>
<script>
export default {
  name: 'FooterComponents'
}
</script>
